import { ApolloClient, ApolloLink, ApolloProvider } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import ApolloLinkTimeout from "apollo-link-timeout";
import * as React from "react";

import { MetaViewModel } from "@/view-models/MetaViewModel";

import { apolloErrorHandler } from "./apollo-error-handler";
import { readToken } from "./auth-utils";
import createHttpLink from "./http-link";
import { securityLoggedOut } from "./logout-utils";

type Props = {
  uri: string;
  debug: boolean;
  children: React.ReactNode;
  meta: MetaViewModel;
};

const MAX_ATTEMPTS = 5;
const MAX_ATTEMPTS_NO_STATUSCODE = 3;
const TIMEOUT = 90000;

export function createApolloClient(
  uri: string,
  debug: boolean,
  meta: MetaViewModel
) {
  const cache = new InMemoryCache();
  const httpLink = createHttpLink(uri);
  const timeoutLink = new ApolloLinkTimeout(TIMEOUT);

  const authLink = new ApolloLink((operation, forward) => {
    const token = readToken();

    operation.setContext(({ headers = {} }) => {
      if (token)
        headers = {
          authorization: `Bearer ${token}`, // however you get your token
          ...headers,
        };

      return { headers };
    });

    if (token) return forward(operation);

    securityLoggedOut(window.history.state, meta);

    return null;
  });

  //Composing the link chain
  const additiveLink = ApolloLink.from([
    onError(apolloErrorHandler),
    timeoutLink,
    new RetryLink({
      delay: {
        initial: 100,
        jitter: true,
      },
      attempts: (count, operation, error) => {
        /*
         * Case 1: error.statusCode is falsy and attempt count is below MAX_ATTEMPTS_NO_STATUSCODE threshold
         * sends operation and error to apolloErrorHandler
         * allows next attempt
         */
        if (!error.statusCode && count < MAX_ATTEMPTS_NO_STATUSCODE) {
          apolloErrorHandler({
            networkError: error,
            operation,
          });

          return true;
        }

        /*
         * Case 2: count is above MAX_ATTEMPTS threshold
         * stops retries
         */
        if (count > MAX_ATTEMPTS) {
          return false;
        }

        /*
         * Case 3: error.statusCode is falsy OR error.statusCode is between 400 (included) and 407 (included)
         * stops retries
         */
        if (
          !error.statusCode ||
          (error.statusCode >= 400 && error.statusCode < 408)
        ) {
          return false;
        } else {
          /*
           * Case 4: error.statusCode is truthy OR error.statusCode is below 400 or above 407
           */
          let logDetails: any = { operation };
          /*
           * 4.a: error.statusCode is above 499
           * constructs operation and error object for apolloErrorHandler
           * sends constructed operation and error to apolloErrorHandler
           */
          if (error.statusCode >= 500) {
            logDetails = { ...logDetails, networkError: error };
          }
          /*
           * 4.b: error.statusCode is above 399 and below 500
           */
          if (error.statusCode >= 400 && error.statusCode < 500) {
            logDetails = { ...logDetails, graphQLErrors: [error] };
          }
          apolloErrorHandler(logDetails);
        }

        return true;
      },
    }),
    authLink,
    httpLink,
  ]);

  return new ApolloClient({
    connectToDevTools: debug,
    defaultOptions: {
      query: {
        errorPolicy: "all",
      },
      mutate: {
        errorPolicy: "all",
      },
    },
    cache,
    link: additiveLink,
  });
}

function Apollo({ uri, children, debug, meta }: Props) {
  const client = React.useRef<ApolloClient<any>>();
  if (!client.current) {
    client.current = createApolloClient(uri, debug, meta);
  }

  return <ApolloProvider client={client.current}>{children}</ApolloProvider>;
}

Apollo.displayName = "Apollo";
Apollo.defaultProps = {
  debug: process.env.NODE_ENV === "development",
  uri: "/graphql",
};

export default Apollo;
