import * as React from "react";
import { useTranslation } from "react-i18next";

import ActionModal from "@/components/Modals/ActionModal";
import AsyncModal from "@/components/Modals/AsyncModal";
import { captureMessage } from "@/utils/error-logging";
import { generateRedirectUrl } from "@/utils/url-helpers";
import { AuthViewModel } from "@/view-models/AuthViewModel";
import { MetaViewModel } from "@/view-models/MetaViewModel";

import { logout } from "../logout-utils";

export type UserAuthMessage =
  | null
  | "inactiveWarning"
  | "loggedOut"
  | "securityLoggedOut"
  | "forbiddenLoggedOut";

type Props = {
  auth: AuthViewModel;
  meta: MetaViewModel;
  message: UserAuthMessage;
  extra?: {
    redirectUrl?: string;
  };
  onClosed?: () => void;
};

function getModalProps(
  { message, auth, meta, extra }: Props,
  handleClose: () => void,
  t: (label: string) => string
) {
  if (!message) return null;
  const base = {
    headline: t(`${message}Headline`),
    body: t(`${message}Body`),
    actions: [
      {
        label: t("common:close"),
        variation: "primary",
        onClick: handleClose,
      },
    ],
  };
  if (message === "inactiveWarning") {
    return {
      ...base,
      actions: [
        {
          label: t("inactiveActionContinue"),
          onClick: handleClose,
          variation: "primary",
          testId: "continue",
        },
        {
          label: t("inactiveActionLogout"),
          onClick: () => {
            logout(auth.logout, meta.currentArea || "/");
          },
          variation: "text",
          testId: "logout",
        },
      ],
    };
  } else if (message === "securityLoggedOut") {
    return {
      ...base,
      actions: [
        {
          label: t("securityActionLogin"),
          variation: "primary",
          onClick: () => {
            window.location.assign(
              generateRedirectUrl(
                auth.login,
                extra ? extra.redirectUrl : undefined
              )
            );
          },
          testId: "login",
        },
        {
          label: t("securityActionCancel"),
          variation: "text",
          onClick: handleClose,
        },
      ],
    };
  } else if (message === "loggedOut") {
    return base;
  } else if (message === "forbiddenLoggedOut") {
    return base;
  } else {
    captureMessage("Trying to open unknown modal in AuthMessage: " + message);

    return null;
  }
}

function AuthMessages(props: Props) {
  const [shown, setShown] = React.useState(!!props.message);
  const [t, , ready] = useTranslation("auth");

  if (props.message) {
    if (!ready) return null;
    const handleClose = () => setShown(false);
    if (
      props.message === "forbiddenLoggedOut" ||
      props.message === "loggedOut"
    ) {
      return (
        <ActionModal
          actions={[
            {
              label: t("common:close"),
              variation: "primary",
              onClick: handleClose,
            },
          ]}
          body={t(`${props.message}Body`)}
          headline={t(`${props.message}Headline`)}
          isOpen={shown}
          onClosed={props.onClosed}
          onRequestClose={handleClose}
        />
      );
    }
    const values = getModalProps(props, handleClose, t);
    if (!values) return null;

    return (
      <AsyncModal
        isOpen={shown}
        modal="action"
        onClosed={props.onClosed}
        onRequestClose={handleClose}
        {...values}
      />
    );
  }

  return null;
}

export default AuthMessages;
