import Cookies, { CookieAttributes } from "js-cookie";

const TOKEN = "access_token";
const REFRESH_TOKEN = "refresh_token";

const options: CookieAttributes = {
  secure:
    typeof window !== "undefined" && window.location.protocol === "https:",
  sameSite: "strict",
};

/**
 * Read the current token from session storage
 **/
export function readToken(): string {
  try {
    return Cookies.get(TOKEN) || "";
  } catch (e) {
    return "";
  }
}

export function readRefreshToken(): string {
  try {
    return Cookies.get(REFRESH_TOKEN) || "";
  } catch (e) {
    return "";
  }
}

export function updateTokens(accessToken: string, refreshToken?: string) {
  try {
    Cookies.set(TOKEN, accessToken, options);
    if (refreshToken) Cookies.set(REFRESH_TOKEN, refreshToken, options);
  } catch (e) {
    console.error(e);
  }
}

export function clearToken() {
  try {
    Cookies.remove(TOKEN, options);
    Cookies.remove(REFRESH_TOKEN, options);
    if (process.env.NODE_ENV === "development") {
      console.log("The access token was cleared from the session");
    }

    // Remove representing self
    window.localStorage.removeItem("representing_self");
  } catch (e) {}
}

export function extractHashTokens(hash: string): string | null | undefined {
  const keys = [TOKEN, REFRESH_TOKEN];
  const params = new URLSearchParams(hash.substring(1).replace("#", "&"));
  const values = {};
  keys.forEach((key) => {
    if (params.has(key)) {
      values[key] = params.get(key);
      params.delete(key);
    }
  });

  if (values[TOKEN]) {
    updateTokens(values[TOKEN], values[REFRESH_TOKEN] || "");

    return params.toString().replace("&", "#");
  }

  return undefined;
}
