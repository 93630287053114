import { ApolloLink, createHttpLink } from "@apollo/client";
import { path } from "ramda";

import { ACCESS_DENIED } from "../utils/actions";
import { dispatch } from "../utils/dispatcher";
import { apolloErrorHandler } from "./apollo-error-handler";

const errorCheck = (err) =>
  path(["extensions", "category"], err) === "forbidden";

const checkAccessDenied = (errors) => {
  if (errors.some(errorCheck)) {
    dispatch(ACCESS_DENIED);
  }
};

const loggerLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (typeof response.errors === "object") {
      apolloErrorHandler({
        graphQLErrors: response.errors,
        response,
        operation,
        forward,
      });
      checkAccessDenied(response.errors);
    }

    return response;
  });
});

/**
 * The HTTP Link is placed in a separate file
 * This allows us to mock it with a schemaLink:
 * https://medium.com/@garyaparker/mocking-apollo-2-client-data-in-react-with-webpack-schema-link-and-graphql-tools-cf0f1b356b6d
 *
 * This is controlled in the Webpack config file. See:
 * tools/webpack/common.config.ts
 * */
export default (uri: string) => {
  return loggerLink.concat(
    createHttpLink({
      uri,
    })
  );
};
