import { gql } from "@apollo/client";

export const createSubmissionMutation = gql`
  mutation CreateSubmissionMutation(
    $vurderingsaar: Int
    $vurderingsejendom_id: Int
    $type: IndsendelseType!
  ) {
    indsendelse_opret(
      type: $type
      vurderingsaar: $vurderingsaar
      vurderingsejendom_id: $vurderingsejendom_id
    ) {
      indsendelse_id
    }
  }
`;

export const GET_UPLOAD_URL = gql`
  mutation CreateUploadURL($bilag_input: [BilagInput], $indsendelse_id: UUID!) {
    indsendelse_tilfoej_bilag(
      bilag_input: $bilag_input
      indsendelse_id: $indsendelse_id
    ) {
      bilag_id
      navn
      udloebsdato
      url
    }
  }
`;

export const SAVE_SUBMISSION = gql`
  mutation saveSubmission($indhold: String!, $indsendelse_id: UUID!) {
    indsendelse_gem(indhold: $indhold, indsendelse_id: $indsendelse_id) {
      oprettet
      opdateret
      tilstand
      type
      vurderingsaar
      vurderingsejendom_id
      indhold
      indsendelse_id
    }
  }
`;

export const REMOVE_SUBMISSION_ATTACHMENT = gql`
  mutation removeSubmissionAttachment(
    $attachmentId: UUID!
    $submissionId: UUID!
  ) {
    indsendelse_fjern_bilag(
      bilag_id: $attachmentId
      indsendelse_id: $submissionId
    ) {
      indsendelse_id
    }
  }
`;

export const SUBMIT_SUBMISSION = gql`
  mutation SubmitSubmittion($indsendelse_id: UUID!) {
    indsendelse_indsend(indsendelse_id: $indsendelse_id) {
      oprettet
      opdateret
      indhold
      tilstand
      indsendelse_id
      vurderingsaar
      vurderingsejendom_id
    }
  }
`;

export const DELETE_SUBMISSION = gql`
  mutation DeleteSubmission($indsendelse_id: UUID!) {
    indsendelse_slet_kladde(indsendelse_id: $indsendelse_id) {
      indsendelse_id
    }
  }
`;
