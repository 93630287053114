import { QueryHookOptions } from "@/graphql/types";

import usePropertyData from "./usePropertyData";

const useRichText = ({
  valuationYear,
  propertyId,
  skip,
  ...rest
}: QueryHookOptions) =>
  usePropertyData({
    valuationYear,
    propertyId,
    skip,
    ...rest,
    directives: {
      visRichText: true,
      visDeklarationer: true,
      visTilbageBetaling: true,
      visHistoriskVurderingssag: true,
    },
    caller: useRichText.name,
  });

export default useRichText;
