import { ServerError, ServerParseError } from "@apollo/client";
import { ErrorResponse } from "@apollo/client/link/error";
import * as Sentry from "@sentry/browser";
import { GraphQLError } from "graphql";

import { clearToken } from "./auth-utils";

const AMZN_HEADERS = ["x-amz-apigw-id", "x-amzn-requestid", "x-amzn-trace-id"];

function shouldLogError(error: GraphQLError) {
  if (
    error.extensions &&
    error.path &&
    error.path.length &&
    ((error.path[0] === "current_user" &&
      error.extensions.category === "forbidden") ||
      error.extensions.category === "privilege_error")
  ) {
    return false;
  }

  return true;
}

const handleNetworkError = (
  scope,
  networkError: ServerError | ServerParseError | Error
) => {
  scope.setTag("area", "api-network-error");
  // sets error tag based on hot page data
  scope.setTag("errorTag", global?.__HOT_PAGE_DATA?.meta?.errorTag);

  if ("response" in networkError) {
    scope.setExtra("headers", networkError.response.headers);
    scope.setExtra(
      "apiResponseCode",
      networkError.response.status || networkError.statusCode
    );
  }
};

const extractAmznHeaders = (headers: Headers) => {
  if (!headers) return null;
  if ("forEach" in headers) {
    const output = {};

    headers.forEach((value, key) => {
      const lowerKey = key.toLowerCase();
      if (AMZN_HEADERS.includes(lowerKey)) {
        output[lowerKey] = value;
      }
    });

    return output;
  }

  return null;
};

/**
 * Capture Apollo errors, and send them to Sentry
 * */
export function apolloErrorHandler({
  graphQLErrors,
  networkError,
  operation,
}: Omit<ErrorResponse, "forward"> & Partial<Pick<ErrorResponse, "forward">>) {
  if (graphQLErrors) {
    const { headers } = operation.getContext();
    const amznHeaders = extractAmznHeaders(headers);

    graphQLErrors.filter(shouldLogError).forEach((error) => {
      const message = error.message;
      Sentry.withScope((scope) => {
        let response = undefined;
        if (error.message.includes("Error: {")) {
          try {
            response = JSON.parse(
              error.message.replace(/Error: ({[\w\W]+})/, "$1")
            );
          } catch (e) {}
        }
        if (response) scope.setExtra("response", response);
        scope.setExtra("locations", error.locations);
        scope.setExtra("path", error.path);
        if (operation) {
          // Prefer the operationName as the Sentry message - makes it easier to get an overview in the Dashboard
          scope.setExtra(
            "message",
            `GraphQL Error: ${operation.operationName}`
          );
          scope.setExtra("operation", {
            operationName: operation.operationName,
            variables: operation.variables,
          });
          scope.setTag("area", "api-graphql-error");
          scope.setTag(
            "errorTag",
            global.__HOT_PAGE_DATA?.meta?.errorTag ||
              global.__INITIAL?.meta?.errorTag
          );

          if (operation.getContext()) {
            scope.setExtra(
              "apiResponseCode",
              operation.getContext()?.response?.status
            );
            amznHeaders &&
              Object.keys(amznHeaders).forEach((key) => {
                scope.setExtra(key, amznHeaders[key]);
              });
          }
        }

        if (networkError) {
          handleNetworkError(scope, networkError);
        }

        if (error.path) scope.setTag("graphql.path", error.path.join(", "));
        Sentry.captureMessage(message);
      });

      if (process.env.NODE_ENV === "development") {
        console.error(`[GraphQL error]: Message: ${error.message}`);
      }
    });
  } else if (networkError && "statusCode" in networkError) {
    if (
      operation.operationName === "GetUser" &&
      (networkError.statusCode === 401 || networkError.statusCode === 403)
    ) {
      // Tried to get the user, but the access_token is invalid. Clear it, since it will never be valid.
      clearToken();
    } else {
      Sentry.withScope((scope) => {
        if (operation) {
          scope.setExtra("operation", {
            operationName: operation.operationName,
            variables: operation.variables,
          });
        }

        if (networkError) {
          handleNetworkError(scope, networkError);
        }
        scope.setExtra("message", `Network Error: ${operation.operationName}`);
        Sentry.captureMessage(
          `Network error: ${networkError.message}`,
          Sentry.Severity.Error
        );
      });

      if (process.env.NODE_ENV === "development") {
        console.error(`[Network error]: ${networkError}`);
      }
    }
  }
}
