import { useAtom } from "jotai";
import React from "react";

import { placeholderToggleAtom } from "@/store";

import { FullQueryResult, QueryHookOptions } from "../../../graphql/types";
import { PropertyDataContext } from "../../contexts/PropertyDataContext";

const isOnPage = process.env.NODE_ENV !== "test" && !process.env.STORYBOOK;

/**
 * Hook to request property data and fetch it through a `PropertyDataContext` instance.
 */
const usePropertyData = (
  options: QueryHookOptions
): FullQueryResult | { data: null; loading: boolean; error: null } => {
  const context = React.useContext(PropertyDataContext);
  const [, setShowPlaceholder] = useAtom(placeholderToggleAtom);
  const { load, queryResponse } = context ?? {};
  const mountedRef = React.useRef<boolean>(false);
  const dataLoaded = isOnPage // don't wait for data to load in tests or storybook
    ? !!queryResponse?.data &&
      queryResponse?.called &&
      queryResponse?.loading === false
    : true;

  const { loading } = queryResponse ?? {};
  const { skip = false } = options;

  React.useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (
      options.showPlaceholder &&
      typeof setShowPlaceholder === "function" &&
      isOnPage &&
      !queryResponse?.data
    ) {
      setShowPlaceholder(true);
    }
    // if (loading || mountedRef.current === false) return;
    if (typeof load === "function") {
      load(options).then((data) => {
        if (
          !mountedRef.current &&
          options.callback &&
          typeof options.callback === "function"
        ) {
          options.callback(data);
        }
      });
    }
  }, [load, loading, options, setShowPlaceholder, queryResponse, skip]);

  // don't pass queryResponse to skipped hooks
  return skip === false && dataLoaded
    ? queryResponse
    : { data: null, loading: true, error: null };
};

export default usePropertyData;
