import { ApolloClient } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import { History } from "history";

import { MetaViewModel } from "@/view-models/MetaViewModel";

import { clearToken, readToken } from "./auth-utils";
/**
 * Perform the logout by creating a form that submits
 * @param postUrl
 * @param redirectUrl
 * @param loggedOutParam
 */
export function logout(
  postUrl: string,
  redirectUrl = "/",
  loggedOutParam = "?loggedout=true"
) {
  const form = document.createElement("form");
  const hiddenField = document.createElement("input");
  const hiddenField2 = document.createElement("input");

  form.setAttribute("action", postUrl);
  form.setAttribute(
    "method",
    (process.env.NODE_ENV === "development" ||
      process.env.GRAPHQL_MOCK === "true") &&
      postUrl.endsWith(".html")
      ? "GET"
      : "POST"
  );
  hiddenField.setAttribute("type", "hidden");
  hiddenField.setAttribute("name", "redirectUrl");
  hiddenField.setAttribute("value", redirectUrl + loggedOutParam);

  hiddenField2.setAttribute("type", "hidden");
  hiddenField2.setAttribute("name", "auth-token");
  hiddenField2.setAttribute("value", readToken());

  form.appendChild(hiddenField);
  form.appendChild(hiddenField2);

  document.body && document.body.appendChild(form);

  // Logged out, and the user is no longer valid
  clearToken();

  form.submit();
}

export function securityLoggedOut(
  { push }: History,
  meta: MetaViewModel,
  client?: ApolloClient<InMemoryCache>
) {
  clearToken();
  const currentSite = window.location.protocol + "//" + window.location.host;
  if (meta.currentArea && meta.currentArea.startsWith(currentSite)) {
    const pathname = meta.currentArea.substring(currentSite.length) || "";
    push(pathname);
  } else {
    // Another domain for some reason, or no currentArea - hard redirect
    window.location.assign(
      (meta.currentArea || "/") + "?securityloggedout=true"
    );
  }

  if (client) return client.resetStore();
}
